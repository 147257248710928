<template>
  <v-modal :title="title">
    <div class="modal-body">
      <div class="py-2">{{ message }}</div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" @click="$emit('cancel')">{{ cancelLabel }}</button>
      <button type="button" class="btn" :class="submitBtnClass" @click="$emit('confirm')">{{ confirmLabel }}</button>
    </div>
  </v-modal>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: 'Confirm'
    },
    message: {
      type: String,
      required: true
    },
    variant: {
      type: String,
      required: false
    },
    cancelLabel: {
      type: String,
      default: 'Cancel'
    },
    confirmLabel: {
      type: String,
      default: 'Confirm'
    }
  },
  computed: {
    submitBtnClass () {
      if (this.variant === 'danger') {
        return 'btn-danger'
      }
      return 'btn-primary'
    }
  }
}
</script>
